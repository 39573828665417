import axios from 'axios';
import store from '../store/index.js'
import { Message } from 'element-ui';

const http = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
  timeout: 60000,
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
  },
});

const httpMannger = axios.create({
  // baseURL: base,
  timeout: 60000,
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
  }
});

const httpManngerPost = axios.create({
  // baseURL: base,
  timeout: 60000,
  headers: {
    'Content-Type': 'application/json'
  },
});

const httpManngerMain = axios.create({
  // baseURL: base,
  timeout: 60000,
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
  }
});

const httpHD = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL_HD,
  timeout: 60000,
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
  },
});

const httpSD = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL_SD,
  timeout: 60000,
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
  },
});

const httpST = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL_SATELLITE,
  timeout: 60000,
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
  },
});

const httpt3 = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL_T3,
  timeout: 60000,
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
  },
});


// 添加一个请求拦截器
httpManngerMain.interceptors.request.use(config => {
  const token = localStorage.getItem('myToken');
  console.log('请求拦截===>', token);
  if (token) {
    config.headers.Authorization = `${token}`;
  }
  return config;
}, error => {
  return Promise.reject(error);
});

const requestFunc = (config) => {
  const token = localStorage.getItem('myToken');
  console.log('请求拦截===>', token);
  if (token) {
    config.headers.Authorization = `${token}`;
  }
  return config;
}

httpManngerMain.interceptors.request.use(requestFunc);
httpManngerPost.interceptors.request.use(requestFunc);

/**
 * @description 接口调用成功的拦截器
 * @param {Object} response axios封装的接口返回值
 * @returns {Object} 处理后的返回值
 * @author yujie
 */
const success = (response) => {
  const { data } = response;
  if(response.headers.authorization) {
    store.commit('setToken', response.headers.authorization);
  }
  // TODO 登录校验
  if (data.code == 200) {
    return data.data;
  }

  if (data.status === '0' || JSON.stringify(data) === '{}' || data.status === '404' || response.status === 200) {
    return data;
  }

  Message({
    type: 'error',
    message: data.message,
  });

  return Promise.reject(data);
};

/**
 * @description 接口调用失败的拦截器
 * @param {Object} error 错误信息
 * @returns {Object}
 * @author yujie
 */
const failed = (error) => {
  if (error.message.includes('timeout')) {
    return error;
  }
  Message({
    type: 'error',
    message: '服务器出错，请联系管理员',
  });
  // TODO 不同错误码的验证
  return Promise.reject(error);
};

http.interceptors.response.use(success, failed);
httpManngerMain.interceptors.response.use(success, failed);
httpManngerPost.interceptors.response.use(success, failed);
httpMannger.interceptors.response.use(success, failed);
httpHD.interceptors.response.use(success, failed);
httpt3.interceptors.response.use(success, failed);
httpST.interceptors.response.use(success, failed);
httpSD.interceptors.response.use(success, failed);


export { http, httpManngerMain, httpManngerPost, httpMannger, httpHD, httpt3, httpST, httpSD };
