<template>
  <div
    class="legend_loop"
    :class="{
      mobile: mobile,
      left: viewHeight >= 800,
      right: viewHeight < 800,
    }"
  >
    <div v-for="(legend, i) of list" :key="i" class="legend">
      <!-- <div v-if="!isMobile" class="unit_wrap">
        <div class="legend_single unit">
          {{ legend.unit }}
        </div>
      </div> -->
      <div v-if="!isMobile" class="color_wrap">
        <div
          class="left"
          :style="{
            background: getColor(legend),
            height: `${legend.colors.length * 20}px`,
          }"
          :class="{
            rain_left: legend.unit.indexOf('hr') > -1,
          }"
        />
        <div class="right">
          <div
            v-for="(item, index) of legend.colors"
            :key="index"
            :class="{ 'text-style': isShowStyle }"
            class="legend_single color"
          >
            {{ legend.values[index] | formatValue(index, legend.colors.length - 1, mobile, factor) }}
          </div>
        </div>
      </div>
      <div
        v-else
        class="color_wrap"
        :style="{
          background: getColor(legend),
        }"
      >
        <div v-for="(item, index) of legend.colors" :key="index" class="legend_single color">
          {{ legend.values[index] | formatValue(index, legend.colors.length - 1, mobile, factor) }}
        </div>
      </div>
      <div v-if="isMobile" class="unit_wrap">
        <div class="legend_single unit">
          {{ legend.name }}
        </div>
        <div class="legend_single unit">
          {{ legend.unit }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import { drag } from "@/utils/drag";
import parseDataConfig from "@/config/ColorBar";
import { factorMap, factorFour } from "@/config/MapConfig";

export default {
  filters: {
    formatValue(value, index, length, mobile,factor) {
      let inx = 1
      if (factor === 'rh2m'){
        return value;
      }
      const border = mobile ? length : inx;
      return index === border ? value + (value ? "+" : "") : value;
    },
  },
  props: {
    factor: {
      type: String,
      required: true,
    },
    mobile: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState(["isMobile", "layerType"]),
    ...mapState("map", ["viewHeight", "layerType","timeType"]),
    list() {
      if (factorFour[this.factor]) {
        return [
          this.create("rain"),
          this.create("ice"),
          this.create("graupel"),
          this.create("snow"),
        ];
      }
      return [this.create(factorMap[this.factor])];
    },
    unit() {
      return {
        temp: "℃",
        wind: "m/s",
        rain: "mm/hr",
        snow: "mm/hr",
        ice: "mm/hr",
        graupel: "mm/hr",
        prer: "mm",
        pre_total: 'mm',
        pres_all: 'mm',
        pres: "mm",
        prei: "mm",
        preg: "mm",
        slp: "mb",
        ri_min: "",
        pratesfc: "mm/hr",
        max_reflectivity: "dBz",
        base_reflectivity: "dBz",
        rh2m: "%",
        dust_conc: "μg/m³",
        dod: "",
        dust_ddep: "mg/m²",
        dust_emis: "mg/㎡/h",
        dswrfsfc: "W/m²",
        vis_cats: "km",
      };
    },

    name() {
      return {
        temp: "温度",
        wind: "风速",
        rain: "降雨",
        snow: "降雪",
        ice: "降冰",
        graupel: "霰降水",
        prer: "液态降水量",
        pre_total: '总降水量',
        pres_all: '固态降水量',
        pres: "降雪",
        prei: "降冰",
        preg: "霰降水",
        slp: "气压",
        ri_min: "指数",
        pratesfc: "总降水率",
        max_reflectivity: "雷达",
        base_reflectivity: "雷达",
        rh2m: "相对湿度",
        dust_conc: "地表沙尘浓度",
        dod: "沙尘光学厚度",
        dust_ddep: "累计沉降",
        dust_emis: "扬沙通量",
        dswrfsfc: "辐照度",
        vis_cats: "能见度",
      };
    },

    isShowStyle: {
      get() {
        const arr = [
          "base_reflectivity",
          "pratesfc",
          "max_reflectivity",
          "ri_min",
          "prerrt",
          "presrt",
          "pregrt",
          "preirt",
          "prer",
          "pres",
          "preg",
          "prei",
        ];
        return arr.indexOf(this.factor) !== -1 && this.layerType !== "shadow";
      },
    },
  },

  mounted() {
    if (!this.mobile) {
      drag(this.$el);
    }
    // window.addEventListener('resize', () => {
    //   this.setViewHeight(getViewPortHeight());
    // });
  },
  beforeDestroy() {
    // window.removeEventListener('resize', () => {
    //   this.setViewHeight(getViewPortHeight());
    // });
  },

  methods: {
    ...mapMutations("map", ["setViewHeight"]),
    create(factor) {
      let factorValue;
      if (factor === "pratesfc" && this.timeType.type>1){
        factorValue = `${factor}_${this.timeType.type}hr`
      }else {
        factorValue = factor
      }

      let values = [...parseDataConfig.values[factorValue]];
      let colors = [...parseDataConfig.color_arrays[factor]];
      if (factor !== "ri_min" && !this.mobile) {
        if (factor === "dust_conc") {
          values = JSON.parse(
            JSON.stringify([0,  "", "", 300, "", "", 2000, "", "", 3500, "", "", 14000])
          ).reverse();
        } else if (factor === "vis_cats") {
          values = JSON.parse(
            JSON.stringify([0,  "", "不佳", "", 2, "", "一般", "", 5, "", "好", ""])
          ).reverse();
        } else {
          values = JSON.parse(JSON.stringify(parseDataConfig.values[factorValue])).reverse();
        }
        colors = JSON.parse(JSON.stringify(parseDataConfig.color_arrays[factor])).reverse();
      }else if(this.mobile){
        if (factor !== "ri_min") {
          if (factor === "dust_conc") {
            values = JSON.parse(
              JSON.stringify([0,  "", "", 300, "", "", 2000, "", "", 3500, "", "", 14000])
            );
          } else if (factor === "vis_cats") {
            values = JSON.parse(
              JSON.stringify([0,  "", "不佳", "", 2, "", "一般", "", 5, "", "好", ""])
            );
          } else {
            values = JSON.parse(JSON.stringify(parseDataConfig.values[factorValue]));
          }
        }
      }
      const unit = this.unit[factor];
      const name = this.name[factor];
      if (unit === '') {
        values.push(unit);
      }else {
        if (this.mobile){
          if (factor === "vis_cats"){
            values.push("");
            colors.unshift(colors[colors.length-1])
          }
        }else {
          values.splice(0, 0, unit);
          colors.unshift(colors[0]);
        }
      }
      return {
        name,
        unit,
        values,
        colors,
      };
    },

    getColor(legend) {
      console.log(legend,238);
      const { colors } = legend;
      const first = colors[0];
      const last = colors[colors.length - 1];
      const step = 100 / (colors.length + 1);
      const str = `, rgba(${first[0]}, ${first[1]}, ${first[2]}, 1) 0%`;
      let result = colors.reduce((target, item, index) => {
        target += `, rgba(${item[0]}, ${item[1]}, ${item[2]}, 1) ${step * (index + 1)}%`;
        return target;
      }, str);

      result += `, rgba(${last[0]}, ${last[1]}, ${last[2]}, 1) 100%`;

      return `linear-gradient(to ${this.mobile ? "right" : "bottom"}${result})`;
    },
  },
};
</script>
<style lang="scss" scoped>
.legend_loop {
  position: absolute;
  right: 22px;
  bottom: 67px;
  z-index: 100000;
  display: flex;

  .legend {
    cursor: move;

    .color_wrap {
      display: flex;
      border-radius: 0 0 6px 6px;
      backdrop-filter: blur(1px);

      .left {
        width: 50px;
        border-radius: 6px;
        // box-shadow: 0 0 4px 0 black;
      }

      .rain_left {
        width: 37px !important;
      }

      .right {
        position: absolute;
        width: 100%;
        //right: -5px;
      }

      //.text-style {
      //  text-shadow: 0px 0px 1px black !important;
      //  color: #303235 !important;
      //}
    }

    .legend_single {
      border-radius: 6px 6px 0 0;
      height: 20px;
      line-height: 20px;
      font-size: 12px;
      color: #fff;
      text-align: center;
      min-width: 24px;

      &.color {
        //text-indent: 5px;
        text-align: center;
        text-shadow: -1px 0 1px rgba(0, 0, 0, 0.6), 1px 0 1px rgba(0, 0, 0, 0.6), 0 1px 1px rgba(0, 0, 0, 0.6), 0 -1px 1px rgba(0, 0, 0, 0.6);
        // text-shadow: 0 0 2px #000;
      }

      &.black {
        color: #000;
      }

      &.unit {
        background: $bg-color;
        text-align: center;
        color: $text-operate;
      }
    }
  }

  &.mobile {
    width: 100%;
    box-sizing: border-box;
    top: auto;
    bottom: 0;
    left: 0;

    .legend {
      display: flex;
      margin-left: 0;
      width: 100%;

      .color_wrap {
        justify-content: space-around;
        border-radius: 0;
      }

      .unit_wrap {
        width: 60px;
      }

      .legend_single {
        color: #fff;
        text-align: center;
        padding: 0;
        white-space: nowrap;
        min-width: 0;

        &.unit {
          background: #fff;
          color: #333;
          height: 10px;
          line-height: 10px;
          border: none;
          border-radius: 0;
        }

        &.unit:first-child {
          padding-top: 3px;
        }

        &.unit:last-child {
          padding-bottom: 3px;
        }
      }
    }

    .color_wrap {
      display: flex;
      width: calc(100% - 60px);
    }
  }
}
</style>
